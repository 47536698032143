import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { pictureFluidType } from '../../../types/picture';
import { FluidPicture } from '../../common/picture';
import { useModal } from '../../../hooks/useModal';
import './team.scss';

const bem = new BEMHelper({
  name: 'content-team',
});

const modal = new BEMHelper({
  name: 'content-team__modal',
});

export const TeamMember = ({
  title,
  position,
  description,
  image,
}) => {
  const { t } = useTranslation();
  const { showModal } = useModal(
    () => (
      <div {...modal()}>
        <h2 {...modal('title')}>{title}</h2>
        <dl {...modal('position')}>
          <dt {...modal('position__term')}>{t('Position')}</dt>
          <dd {...modal('position__details')}>{position}</dd>
        </dl>
        {parse(description)}
      </div>
    ),
    { title: t('Biography of {{ name }}', { name: title }) },
  );

  return (
    <div {...bem('member')}>
      <h3 {...bem('title')}>{title}</h3>
      <dl {...bem('position')}>
        <dt {...bem('position__term')}>{t('Position')}</dt>
        <dd {...bem('position__details')}>{position}</dd>
      </dl>
      <FluidPicture
        {...image}
        maxWidth={420}
        widths={[105, 210, 420, 630, 840]}
        {...bem('image')}
      />
      <button
        {...bem('button')}
        type="button"
        title={t('Click here to read the biography')}
        onClick={showModal}
      >
        {t('Read bio')}
      </button>
    </div>
  );
};

TeamMember.propTypes = {
  title: string.isRequired,
  position: string.isRequired,
  description: string.isRequired,
  image: pictureFluidType.isRequired,
};

export const Team = ({
  id,
  headline,
  members,
}) => (
  <Content id={id} {...bem()}>
    <h2 {...bem('headline')}>{headline}</h2>
    <ul {...bem('list')}>
      {members.map(({
        slug,
        title,
        position,
        description,
        image: [image],
      }) => (
        <li key={slug} {...bem('list-item')}>
          <TeamMember
            title={title}
            position={position}
            description={description}
            image={image}
          />
        </li>
      ))}
    </ul>
  </Content>
);

Team.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  members: arrayOf(shape({
    title: string.isRequired,
    firstname: string.isRequired,
    lastname: string.isRequired,
    position: string.isRequired,
    description: string.isRequired,
    image: arrayOf(pictureFluidType).isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment TeamContentFragment on CraftGQL_pageContents_team_BlockType {
    id
    headline
    members {
      ...on CraftGQL_teammembers_teammembers_Entry {
        slug
        title
        firstname: teammemberFirstname
        lastname: teammemberLastname
        position: teammemberPosition
        description: teammemberDescription
        image: teammemberImage {
          ...Picture
          fluid_0: url(width: 105, mode: "crop", position: "") # 0.25
          fluid_1: url(width: 210, mode: "crop", position: "") # 0.5
          fluid_2: url(width: 420, mode: "crop", position: "") # max width
          fluid_3: url(width: 630, mode: "crop", position: "") # 1.5
          fluid_4: url(width: 840, mode: "crop", position: "") # 2
        }
      }
    }
  }
`;
